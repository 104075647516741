// @flow
import * as React from 'react'
import { Hero } from '@toggl/ui'
import styled from '@emotion/styled'
import { media } from '@toggl/style'
import IndexLayout from 'layouts'
import LayoutTrack from 'components/LayoutTrack'
import heroBg from 'images/uploads/homepage-hero-bg.jpg'
import videoSrc from 'images/uploads/bg-video.mp4'

declare var process: WebProcess

const HeroComponent = () => {
  return (
    <Hero.Wrapper bgImage={heroBg} bgVideo={videoSrc}>
      <StyledWrapper>
        <Hero.Title centered>Redirecting...</Hero.Title>
      </StyledWrapper>
    </Hero.Wrapper>
  )
}

const SignupCompletedPage = () => {
  React.useEffect(() => {
    if (
      /iPhone|iPod/i.test(window.navigator.userAgent) ||
      /Android/i.test(window.navigator.userAgent)
    ) {
      window.location.assign('/device-unsupported?device=mobile')
      return
    }

    if (/MSIE|trident/i.test(window.navigator.userAgent)) {
      window.location.assign('/device-unsupported?device=browser')
    }

    window.location.assign(process.env.GATSBY_TRACK_APP_URL)
  }, [])

  return (
    <IndexLayout>
      <LayoutTrack Hero={HeroComponent}></LayoutTrack>
    </IndexLayout>
  )
}

const StyledWrapper = styled(Hero.ContentWrapper)`
  ${media.mq[1]} {
    padding: 168px 20px 168px 20px;
  }
`

export default SignupCompletedPage
